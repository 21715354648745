import {
  mapQueryTableCompactClusters,
  mapQueryTableCompactSchemas,
  mapQueryTableListsCount,
  mapQueryTables,
  mapQueryTableSamples,
  mapQueryTablesWidget,
  querySchemaTables,
  mapQuerySchemaTables,
  queryTableCompactClusters,
  queryTableCompactSchemas,
  queryTableListsCount,
  queryTables,
  queryTableSamples,
  queryTablesWidget,
  mapQueryTableFilterAttributes,
  queryTableFilterAttributes,
  queryTableFilterSchemas,
  mapQueryTableFilterSchemas,
  queryTableClusterMetaData,
  mapQueryTableClusterMetaData,
  mutationReviewTables
} from './queries'
import { defaultSortParams, getSortDirection, SortParams } from '../../utils/sortUtil'
import {
  ATTRIBUTE_IDS,
  ATTRIBUTE_SENSITIVITY,
  ATTRIBUTE_SET_IDS,
  DATABASE,
  DATABASE_ID,
  DATA_SOURCE_ID,
  ENTITY_IDS,
  FILTER_PII_VALUES,
  IS_ALL,
  IS_AT_RISK,
  IS_SENSITIVE,
  PAGE,
  RISK_LEVELS,
  SCHEMA,
  SEARCH_QUERY,
  TABLE_ID
} from '../../constants'
import graphqlService from '../../services/graphqlService'
import apiService from '../../services/api/apiService'
import { ColumnsParams } from '../columns/columnsSlice'
import { DropdownLabelItem } from '../documentLabelsV2/types'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export enum TableChangeTypes {
  create = 'CREATE',
  update = 'UPDATE',
  delete = 'DELETE'
}

export type Table = {
  tableId: string
  tableName: string
  columnsSensitiveCount: number
  columnsCount: number
  schemaName: string
  databaseName?: string
  confidence: number
  rowsCount: number
  issuesCount?: number
  risk?: RISK_LEVELS
  lastModified?: string
  modifiedBy?: string
  isReviewed: boolean
  changeType: TableChangeTypes
}

export type TablesWidget = {
  tablesCount: number
  tablesNeedReviewCount: number
  tablesNeedReviewPiiCount: number
  tablesPiiCount: number
  clustersCount: number
  clusterTablesCount: number
  clusterTablesPiiCount: number
  clustersImpactedCount: number
  orphanTablesCount: number
  orphanTablesPiiCount: number
  orphanTablesNeedReviewCount: number
}

export type TableMetaData = {
  identityTableTimestampColumnID?: string
  primaryIdentifierColumnID?: string
  piiColumnIDsToScan?: string[]
  labels?: DropdownLabelItem[]
}

export type TablesListSettings = {
  list?: Table[]
  total?: number
  sort: SortParams
}

interface TablesState {
  all: TablesListSettings
  sensitive: TablesListSettings
  issues: TablesListSettings
  widget?: TablesWidget
  tableSamples?: TableSamples
  tablesListCompact: {
    tables?: TableCompact[]
    tablesTotal?: number
    clusters?: TableCompactCluster[]
    clustersTotal?: number
    schemas?: TableCompactSchema[]
    schemasTotal?: number
  }
  filters: {
    attributes?: TableFilterAttribute[]
    schemas?: TableFilterSchema[]
  }
  metadata?: TableMetaData
  isMetaDataUpdated: boolean
  isMetaDataSaved: boolean
}

const initialList: TablesListSettings = {
  sort: defaultSortParams
}

export const initialState: TablesState = {
  all: initialList,
  sensitive: initialList,
  issues: initialList,
  tablesListCompact: {},
  filters: {},
  isMetaDataUpdated: false,
  isMetaDataSaved: false
}
export interface TableListsCountParams {
  [DATA_SOURCE_ID]: string
  [DATABASE_ID]?: string
  [SEARCH_QUERY]?: string
  piiDetection?: FILTER_PII_VALUES[]
  filters: {
    [ATTRIBUTE_SET_IDS]?: string
    [ATTRIBUTE_IDS]?: string
    [ATTRIBUTE_SENSITIVITY]?: string
    [DATABASE]?: string
    [SCHEMA]?: string
  }
}

export const ACTION_TABLE_LISTS_COUNT = 'tables/listsCount'
export const fetchTableListsCount = createAsyncThunk(
  ACTION_TABLE_LISTS_COUNT,
  async (params: TableListsCountParams) => {
    const resultRaw = await graphqlService.execute(queryTableListsCount(params))
    return mapQueryTableListsCount(resultRaw)
  }
)
export interface TablesListParams {
  [PAGE]: number
  [IS_AT_RISK]?: boolean
  [IS_SENSITIVE]?: boolean
  [DATA_SOURCE_ID]: string
  [DATABASE_ID]?: string
  [SEARCH_QUERY]?: string
  includeColumnCounts?: boolean
  piiDetection?: FILTER_PII_VALUES[]
  filters: {
    [ATTRIBUTE_SET_IDS]?: string
    [ENTITY_IDS]?: string
    [ATTRIBUTE_IDS]?: string
    [ATTRIBUTE_SENSITIVITY]?: string
    [DATABASE]?: string
    [SCHEMA]?: string
  }
}

export const ACTION_TABLES_LIST_FETCH = 'tables/list'
export const fetchTablesList = createAsyncThunk(
  ACTION_TABLES_LIST_FETCH,
  async (params: TablesListParams) => {
    const resultRaw = await graphqlService.execute(queryTables(params))
    const list = mapQueryTables(resultRaw)
    return { list, ...params }
  }
)

export type TableFilterAttribute = {
  id: string
  name: string
  internalName: string
  attributeSets: Array<{ id: string; name: string; enabled: boolean }>
}
export const ACTION_TABLES_FILTER_ATTRIBUTES = 'tables/filterAttributes'
export const fetchTablesFilterAttributes = createAsyncThunk(
  ACTION_TABLES_FILTER_ATTRIBUTES,
  async () => {
    const raw = await graphqlService.execute(queryTableFilterAttributes())
    return mapQueryTableFilterAttributes(raw)
  }
)

export type TableFilterSchema = {
  id: string
  name: string
  databases: Array<{ id: string; name: string }>
}
export type TableFilterSchemaParams = {
  [DATA_SOURCE_ID]: string
}
export const ACTION_TABLES_FILTER_SCHEMAS = 'tables/filterSchemas'
export const fetchTablesFilterSchemas = createAsyncThunk(
  ACTION_TABLES_FILTER_SCHEMAS,
  async (params: TableFilterSchemaParams) => {
    const raw = await graphqlService.execute(queryTableFilterSchemas(params))
    return mapQueryTableFilterSchemas(raw)
  }
)

export type SaveColumnClassificationTableMetaParams = {
  tableId?: string
  metadata: TableMetaData
  createdBy: string
}
export const ACTION_COLUMNS_CLASSIFICATION_SAVE_TABLE_META = 'columns/saveClassificationTableMeta'
export const saveColumnClassificationForTableMeta = createAsyncThunk(
  ACTION_COLUMNS_CLASSIFICATION_SAVE_TABLE_META,
  async (params: SaveColumnClassificationTableMetaParams) => {
    const { tableId = '', metadata, createdBy } = params
    const meta: Record<string, unknown> = {
      primaryIdentifierColumn: metadata.primaryIdentifierColumnID
    }
    if (metadata.identityTableTimestampColumnID) {
      meta.timestampColumn = metadata.identityTableTimestampColumnID
    }
    if (metadata.labels) {
      meta.labels = metadata.labels
    }
    await apiService.postColumnClassifications({
      typeName: 'identity',
      classifications: [
        {
          qualifiedName: tableId,
          identity: {
            isIdentity: true,
            entityType: 'Customer',
            piiColumnsToScan: metadata.piiColumnIDsToScan || [],
            createdBy,
            metadata: meta
          }
        }
      ]
    })
  }
)

export type TableCompact = {
  tableId: string
  tableName: string
  schemaId: string
  columnsCount: number
  rowsCount: number
  parentId: string
  changeType: TableChangeTypes
}
export interface TablesListCompactParams {
  [DATA_SOURCE_ID]: string
}

export type TableCompactCluster = {
  clusterId: string
  clusterName: string
}
export const ACTION_TABLES_CLUSTERS = 'tables/clusters'
export const fetchTableClusters = createAsyncThunk(
  ACTION_TABLES_CLUSTERS,
  async (params: TablesListCompactParams) => {
    const raw = await graphqlService.execute(queryTableCompactClusters(params))
    return mapQueryTableCompactClusters(raw)
  }
)

export const ACTION_TABLES_CLUSTERS_METADATA = 'tables/clustersMetaData'
export const fetchTableClusterMetaData = createAsyncThunk(
  ACTION_TABLES_CLUSTERS_METADATA,
  async (params: ColumnsParams) => {
    const raw = await graphqlService.execute(queryTableClusterMetaData(params))
    return mapQueryTableClusterMetaData(raw)
  }
)

export type TableCompactSchema = {
  schemaId: string
  schemaName: string
}
export const ACTION_DATA_SOURCE_SCHEMAS = 'tables/dataSourceSchemas'
export const fetchDataSourceSchemas = createAsyncThunk(
  ACTION_DATA_SOURCE_SCHEMAS,
  async (params: TablesListCompactParams) => {
    const raw = await graphqlService.execute(queryTableCompactSchemas(params))
    return mapQueryTableCompactSchemas(raw)
  }
)

export type SchemaTablesParams = {
  [DATA_SOURCE_ID]: string
  [PAGE]: number
  schemaId: string
}
export const ACTION_SCHEMA_TABLES = 'tables/dataSourceSchemaTables'
export const fetchSchemaTables = createAsyncThunk(
  ACTION_SCHEMA_TABLES,
  async (params: SchemaTablesParams) => {
    const raw = await graphqlService.execute(querySchemaTables(params), ACTION_SCHEMA_TABLES)
    return mapQuerySchemaTables(raw)
  }
)

export interface TablesWidgetParams {
  [DATABASE_ID]?: string
}

export const ACTION_TABLES_WIDGET_FETCH = 'tables/widget'
export const fetchTablesWidget = createAsyncThunk(
  ACTION_TABLES_WIDGET_FETCH,
  async (params: TablesWidgetParams) => {
    const resultRaw = await graphqlService.execute(queryTablesWidget(params))
    return mapQueryTablesWidget(resultRaw)
  }
)

export type TableSamples = {
  tableId: string
  tableName: string
  columns: Array<{
    columnId: string
    columnName: string
    isPii: boolean
  }>
  rows: Array<Array<string>>
}
export interface TableSamplesParams {
  [TABLE_ID]: string
}

export const ACTION_TABLE_SAMPLES = 'tables/samples'
export const fetchTableSamples = createAsyncThunk(
  ACTION_TABLE_SAMPLES,
  async (params: TableSamplesParams) => {
    const resultRaw = await graphqlService.execute(queryTableSamples(params))
    return mapQueryTableSamples(resultRaw)
  }
)

export const ACTION_TABLES_REVIEW = 'table/review'
export const reviewTables = createAsyncThunk(ACTION_TABLES_REVIEW, async (tableIds: string[]) => {
  return await graphqlService.execute(mutationReviewTables(tableIds))
})

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    resetLists: (state) => {
      state.all = initialState.all
      state.sensitive = initialState.sensitive
      state.issues = initialState.issues
    },
    resetWidget: (state) => {
      state.widget = initialState.widget
    },
    resetTableSamples: (state) => {
      state.tableSamples = initialState.tableSamples
    },
    setTableMetaData: (state, { payload }) => {
      state.metadata = { ...state.metadata, ...payload }
      const hasMeta =
        payload.identityTableTimestampColumnID ||
        payload.primaryIdentifierColumnID ||
        !!payload.piiColumnIDsToScan?.length
          ? true
          : false
      state.isMetaDataUpdated = hasMeta
    },

    resetTableMetaData: (state) => {
      state.metadata = initialState.metadata
    },
    resetSchemaTables: (state) => {
      state.tablesListCompact.tables = initialState.tablesListCompact.tables
      state.tablesListCompact.tablesTotal = initialState.tablesListCompact.tablesTotal
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTablesList.fulfilled, (state, { payload }) => {
      if (payload[IS_ALL]) state.all.list = payload.list
      if (payload[IS_AT_RISK]) state.issues.list = payload.list
      if (payload[IS_SENSITIVE]) state.sensitive.list = payload.list
    })
    builder.addCase(fetchTableListsCount.fulfilled, (state, { payload }) => {
      state.all.total = payload[IS_ALL]
      state.sensitive.total = payload[IS_SENSITIVE]
      state.issues.total = payload[IS_AT_RISK]
    })
    builder.addCase(fetchTablesWidget.fulfilled, (state, { payload }) => {
      state.widget = payload
    })
    builder.addCase(fetchTableClusters.fulfilled, (state, { payload }) => {
      state.tablesListCompact.clusters = payload.clusters
      state.tablesListCompact.clustersTotal = payload.total
    })
    builder.addCase(fetchDataSourceSchemas.fulfilled, (state, { payload }) => {
      state.tablesListCompact.schemas = payload.schemas
      state.tablesListCompact.schemasTotal = payload.total
    })
    builder.addCase(fetchSchemaTables.fulfilled, (state, { payload }) => {
      state.tablesListCompact.tables = payload.tables
      state.tablesListCompact.tablesTotal = payload.total
    })
    builder.addCase(fetchTableSamples.fulfilled, (state, { payload }) => {
      state.tableSamples = payload
    })
    builder.addCase(fetchTablesFilterAttributes.fulfilled, (state, { payload }) => {
      state.filters.attributes = payload
    })
    builder.addCase(fetchTablesFilterSchemas.fulfilled, (state, { payload }) => {
      state.filters.schemas = payload
    })
    builder.addCase(fetchTableClusterMetaData.fulfilled, (state, { payload }) => {
      state.metadata = payload.primaryIdentifierColumnID ? payload : initialState.metadata
      if (payload.primaryIdentifierColumnID) {
        state.isMetaDataUpdated = false
        state.isMetaDataSaved =
          payload.identityTableTimestampColumnID ||
          payload.primaryIdentifierColumnID ||
          !!payload.piiColumnIDsToScan?.length
            ? true
            : false
      }
    })
    builder.addCase(saveColumnClassificationForTableMeta.fulfilled, (state) => {
      state.isMetaDataSaved = true
    })
  }
})

export const {
  setSort,
  setTableMetaData,
  resetLists,
  resetWidget,
  resetTableSamples,
  resetTableMetaData,
  resetSchemaTables
} = tablesSlice.actions

export default tablesSlice.reducer
