import { DataSourceToDelete } from './interfaces'
import {
  mapQueryDataSourceOwners,
  mapQueryDataSources,
  mapQueryDataSourcesByEntity,
  mapQueryDataSourcesRiskyWidget,
  mapQueryDataSourcesStructuredSummary,
  mapQueryDataSourcesUnstructuredSummary,
  mapqueryAttributeInstancesByDataSource,
  queryDataSourceOwners,
  queryDataSources,
  queryDataSourcesByEntity,
  queryDataSourcesRiskyWidget,
  queryDataSourcesStructuredSummary,
  queryDataSourcesUnstructuredSummary,
  queryAttributeInstancesByDataSource,
  mapQueryDataSourcesSummary,
  queryDataSourcesSensitiveTables,
  mapQueryDataSourcesSensitiveTables
} from './queries'
import service from '../../services/api/apiService'
import {
  ConfigAWS,
  ConfigAzureBlob,
  ConfigBox,
  ConfigGSuite,
  ConfigJira,
  ConfigMsOffice,
  DataSource
} from '../../services/api/apiTypes'
import {
  DATASOURCE_CONNECTION,
  DATASOURCE_CREATE_OR_UPDATE_STATUS,
  DATASOURCE_STATUS,
  DATA_SOURCE_ID,
  DATA_SOURCE_TYPES,
  ENTITY_ID,
  CHANNEL_TYPES
} from '../../constants'
import { getSortDirection, defaultSortParams, SortParams } from '../../utils/sortUtil'
import graphqlService from '../../services/graphqlService'
import { datasourcesSummary } from '../../__mocks__/timeMachine'
import { ScanStatus } from '../../interfaces'
import { Drive } from '../../services/graphqlSchemaTypes'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export type StructuredDataSource = {
  id: string
  name: string
  type: DATA_SOURCE_TYPES
  columnsCount: number
  columnsCompletedCount: number
  status?: ScanStatus
  createdBy?: string
  databasesCount?: number
  schemasCount?: number
}

export type StructuredDataSourceSettings = {
  list?: StructuredDataSource[]
  total?: number
  sort: SortParams
}

export interface extraDSTypes {
  instancesDetectedCount?: number
}
export type DataSourceSettings = {
  list?: (DataSource & extraDSTypes)[]
  total?: number
  sort: SortParams
}

export type Attribute = {
  id: string
  name: string
  count: number
}
export type DataSourceSummary = {
  id: string
  name: string
  type: string
}

export type InstancesByDatasourceWidgetItem = {
  id: string
  name: string
  count: number
}

export type DataSourceByEntity = {
  dataSourceId: string
  dataSourceName: string
  dataSourceType: string
  attributesCount: number
  objectsCount: number
  createdBy: string
}

export type DataSourceByEntitySettings = {
  list?: DataSourceByEntity[]
  total?: number
  sort: SortParams
}

export type DataSourceRiskyWidget = {
  total: number
  risky: number
  datasources?: {
    id: string
    name: string
    type: DATA_SOURCE_TYPES
  }[]
}

const initialList: DataSourceSettings = {
  sort: defaultSortParams
}

const initialListByEntity: DataSourceByEntitySettings = {
  sort: defaultSortParams
}

export type IGetAttributeInstancesByDataSourceParams = {
  [ENTITY_ID]?: string
}
export const ACTION_DATA_SOURCES_BY_ENTITY_WIDGET_FETCH = 'dataSources/widgetByEntity'
export const fetchAttributeInstancesByDataSource = createAsyncThunk(
  ACTION_DATA_SOURCES_BY_ENTITY_WIDGET_FETCH,
  async (params: IGetAttributeInstancesByDataSourceParams) => {
    const resultRaw = await graphqlService.execute(queryAttributeInstancesByDataSource(params))
    return mapqueryAttributeInstancesByDataSource(resultRaw)
  }
)

export const ACTION_DATA_SOURCES_SUMMARY_FETCH = 'dataSources/summary'
export const fetchDataSourcesSummary = createAsyncThunk(
  ACTION_DATA_SOURCES_SUMMARY_FETCH,
  async () => {
    const resultRaw = await Promise.resolve(datasourcesSummary)
    return mapQueryDataSourcesSummary(resultRaw)
  }
)

export const ACTION_DATA_SOURCES_BY_ENTITY_FETCH = 'dataSources/listByEntity'
export const fetchDataSourcesByEntity = createAsyncThunk(
  ACTION_DATA_SOURCES_BY_ENTITY_FETCH,
  async (params: IGetAttributeInstancesByDataSourceParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourcesByEntity(params))
    return mapQueryDataSourcesByEntity(resultRaw)
  }
)

export const ACTION_DATA_SOURCES_RISKY_WIDGET = 'dataSources/widgetRisky'
export const fetchDataSourcesRiskyWidget = createAsyncThunk(
  ACTION_DATA_SOURCES_RISKY_WIDGET,
  async () => {
    const resultRaw = await graphqlService.execute(queryDataSourcesRiskyWidget())
    return mapQueryDataSourcesRiskyWidget(resultRaw)
  }
)

export type DataSourceSummaryWidgetParams = {
  [DATA_SOURCE_ID]?: string
}

export type UnstructuredSummaryWidget = {
  objectsCount: number
  filesCount: number
  messagesCount: number
  blobsCount: number
  objectsAtRiskCount: number
  filesAtRiskCount: number
  messagesAtRiskCount: number
  blobsAtRiskCount: number
}
export const ACTION_DATA_SOURCES_UNSTRUCTURED_SUMMARY = 'dataSources/unstructuredSummary'
export const fetchDataSourcesUnstructuredSummary = createAsyncThunk(
  ACTION_DATA_SOURCES_UNSTRUCTURED_SUMMARY,
  async (params: DataSourceSummaryWidgetParams) => {
    const resultRaw = await graphqlService.execute(queryDataSourcesUnstructuredSummary(params))
    return mapQueryDataSourcesUnstructuredSummary(resultRaw)
  }
)

export type StructuredSummaryWidget = {
  databasesCount: number
  tablesCount: number
  totalClusterCount: number
}
export const ACTION_DATA_SOURCES_STRUCTURED_SUMMARY = 'dataSources/structuredSummary'
export const fetchDataSourcesStructuredSummary = createAsyncThunk(
  ACTION_DATA_SOURCES_STRUCTURED_SUMMARY,
  async (params: DataSourceSummaryWidgetParams) => {
    const resultRaw = await graphqlService.execute(
      queryDataSourcesStructuredSummary(params),
      ACTION_DATA_SOURCES_STRUCTURED_SUMMARY
    )
    return {
      dataSourceId: params[DATA_SOURCE_ID],
      data: mapQueryDataSourcesStructuredSummary(resultRaw)
    }
  }
)

export const ACTION_DATA_SOURCES_TABLES_SENSITIVE = 'dataSources/sensitive-tables'
export const fetchDataSourcesSensitiveTables = createAsyncThunk(
  ACTION_DATA_SOURCES_TABLES_SENSITIVE,
  async (id: string) => {
    const resultRaw = await graphqlService.execute(queryDataSourcesSensitiveTables(id))
    return {
      dataSourceId: id,
      data: mapQueryDataSourcesSensitiveTables(resultRaw)
    }
  }
)

export type DataSourcesParams = {
  dataSourceType?: DATA_SOURCE_TYPES
  forPia?: boolean
  status?: DATASOURCE_STATUS
  owner?: string
  page?: number
  limit?: number
  searchText?: string
}

export const ACTION_DATA_SOURCES_LIST = 'dataSources/list'
export const fetchDataSources = createAsyncThunk(
  ACTION_DATA_SOURCES_LIST,
  async (params?: DataSourcesParams) => {
    const results = await graphqlService.execute(queryDataSources(params))
    return mapQueryDataSources(results)
  }
)

export const fetchDataSourceFilters = createAsyncThunk('dataSources/filters', async () => {
  const results = await graphqlService.execute(queryDataSourceOwners())
  const createdBy = [...new Set(mapQueryDataSourceOwners(results))]
  const dataSourceTypes = Object.values(DATA_SOURCE_TYPES)
  const statuses = Object.values(DATASOURCE_STATUS)

  return { createdBy, dataSourceTypes, statuses }
})

export const fetchDataSourceOwners = createAsyncThunk('dataSources/owners', async () => {
  const results = await graphqlService.execute(queryDataSourceOwners())
  return mapQueryDataSourceOwners(results)
})

export const ACTION_GET_DATA_SOURCE_BY_ID = 'dataSources/dataSource/edit'
export const getSelectedDataSource = createAsyncThunk(
  ACTION_GET_DATA_SOURCE_BY_ID,
  async (id: string) => await service.getDataSourceById(id)
)

export const ACTION_DATA_SOURCE_DELETE = 'dataSources/delete'
export const deleteDataSourceById = createAsyncThunk(
  ACTION_DATA_SOURCE_DELETE,
  async (id: string, { rejectWithValue }) => {
    try {
      await service.deleteDataSourceById(id)
      const response = await graphqlService.execute(queryDataSources())
      const result = mapQueryDataSources(response)
      return {
        statusMessage: 'dataSources.delete.success.text',
        ...result
      }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'dataSources.delete.error.text' })
    }
  }
)

export const ACTION_DATA_SOURCE_CREATE = 'dataSources/create'
export const createDatasource = createAsyncThunk(
  ACTION_DATA_SOURCE_CREATE,
  async (data: DataSource, { rejectWithValue }) => {
    try {
      const createResult = await service.postDatasource(data)
      const response = await graphqlService.execute(queryDataSources())
      const result = mapQueryDataSources(response)
      return {
        ...result,
        statusMessage: 'datasources.datasource.create.success.text',
        ...createResult
      }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'datasources.datasource.create.error.text' })
    }
  }
)

export interface ExchangeDatasourceParams {
  name: string
  folderId: string
  srcDatasourceId: string
}
export const ACTION_EXCHANGE_DATA_SOURCE_CREATE = 'dataSource/exchange/create'
export const createExchangeDatasource = createAsyncThunk(
  ACTION_EXCHANGE_DATA_SOURCE_CREATE,
  async (data: ExchangeDatasourceParams, { rejectWithValue }) => {
    try {
      const createResult = await service.postExchangeDatasource(data)
      const response = await graphqlService.execute(queryDataSources())
      const result = mapQueryDataSources(response)
      return {
        ...result,
        statusMessage: 'datasources.datasource.create.success.text',
        ...createResult
      }
    } catch (error) {
      return rejectWithValue({ statusMessage: 'datasources.datasource.create.error.text' })
    }
  }
)

export const ACTION_DATA_SOURCE_UPDATE = 'datasource/update'
export const updateDatasource = createAsyncThunk(
  ACTION_DATA_SOURCE_UPDATE,
  async ({ datasourceId, datasource }: { datasourceId: string; datasource: DataSource }) =>
    await service.putDatasourceById(datasourceId, datasource)
)

export const ACTION_DATA_SOURCE_PARTIAL_UPDATE = 'datasource/update/partial'
export const updateDatasourceFields = createAsyncThunk(
  ACTION_DATA_SOURCE_PARTIAL_UPDATE,
  async ({ datasourceId, datasource }: { datasourceId: string; datasource: DataSource }) =>
    await service.patchDatasourceById(datasourceId, datasource)
)

export const ACTION_DS_TEST_CONNECTION = 'dataSources/dataSource/connection/test'
export const testDataSourceConnection = createAsyncThunk(
  ACTION_DS_TEST_CONNECTION,
  async (data: DataSource, { rejectWithValue }) => {
    try {
      return await service.testConnection(data)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const ACTION_FETCH_DROPBOX_OAUTH_REDIRECT_URL = 'dataSources/dropbox/oauthRedirectUrl'
export const fetchDropboxOauthRedirectUrl = createAsyncThunk(
  ACTION_FETCH_DROPBOX_OAUTH_REDIRECT_URL,
  async (data: DataSource, { rejectWithValue }) => {
    try {
      return await service.fetchOauthRedirectUrl(data)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export type MSTeamsTeam = {
  id: string
  name: string
  type: CHANNEL_TYPES
  teamId?: string
}
export type MSTeamsChannel = MSTeamsTeam

export type MSTeamsParams =
  | { id: string }
  | {
      configuration: {
        datasourceType: string
        name?: string
        microsoftOfficeConfig: {
          clientId: string
          clientSecret: string
          tenantId: string
          eventHubNotificationURL: string
          eventHubName: string
        }
      }
    }
export const ACTION_MS_TEAMS = 'dataSources/msTeams'
export const fetchTeams = createAsyncThunk(
  ACTION_MS_TEAMS,
  async (params: MSTeamsParams) => await service.getMSTeams(params)
)

export type MSTeamsChannelParams = MSTeamsParams & { teamsId: string }
export const ACTION_TEAMS_CHANNELS = 'dataSources/msTeamsChannels'
export const fetchTeamsChannels = createAsyncThunk(
  ACTION_TEAMS_CHANNELS,
  async (params: MSTeamsChannelParams) => await service.getMSTeamsChannels(params, params.teamsId)
)

export type SlackChannel = {
  id: string
  name: string
  type: CHANNEL_TYPES
}
export type SlackChannelParams = {
  botToken?: string
  [DATA_SOURCE_ID]?: string
  type?: CHANNEL_TYPES
}
export const ACTION_SLACK_CHANNELS = 'dataSources/slackChannels'
export const fetchSlackChannels = createAsyncThunk(
  ACTION_SLACK_CHANNELS,
  async (params: SlackChannelParams) => await service.getSlackChannels(params)
)

export type JiraProject = {
  id: string
  name: string
}
export type JiraProjectParams =
  | { id: string; searchQuery?: string }
  | { configuration: { jiraConfiguration: ConfigJira }; searchQuery?: string }
export const ACTION_JIRA_PROJECTS = 'dataSources/jiraProjects'
export const fetchJiraProjects = createAsyncThunk(
  ACTION_JIRA_PROJECTS,
  async (params: JiraProjectParams) => await service.getJiraProjects(params)
)

export type AwsBucketNotificationConfig = {
  topicARNs: string[]
  queueARNs: string[]
  lambdaARNs: string[]
  eventBridgeEnabled: boolean
}

export type AwsS3Bucket = {
  name: string
  region: string
  type?: string
  size?: string
  error?: string
  previousNotificationConfiguration?: AwsBucketNotificationConfig
  currentNotificationConfiguration?: AwsBucketNotificationConfig
}

export type AwsS3BucketsParams =
  | { id: string }
  | { configuration: { awsS3Configuration: ConfigAWS } }
export const ACTION_AWS_S3_BUCKETS = 'dataSources/awsS3Buckets'
export const fetchAwsS3Buckets = createAsyncThunk(
  ACTION_AWS_S3_BUCKETS,
  async (params: AwsS3BucketsParams) => {
    return await service.getAwsS3Buckets(params)
  }
)

export type AwsS3BucketsNotificationsParams =
  | { [DATA_SOURCE_ID]: string; buckets: Array<{ name: string; region: string }> }
  | {
      configuration: { awsS3Configuration: ConfigAWS }
      buckets: Array<{ name: string; region: string }>
    }
  | {
      configuration: { azureBlobConfiguration: ConfigAzureBlob }
      buckets: Array<{ name: string; region: string }>
    }
export const ACTION_AWS_S3_BUCKETS_NOTIFICATIONS = 'dataSources/awsS3BucketsNotifications'
export const fetchAwsBucketsNotifications = createAsyncThunk(
  ACTION_AWS_S3_BUCKETS_NOTIFICATIONS,
  async (params: AwsS3BucketsNotificationsParams) => {
    return await service.getAwsS3BucketsNotifications(params)
  }
)

// Azure Blob
export type AzureBlobSubscription = {
  subscription_id: string
  subscription_name: string
}
export type AzureBlobSubscriptionParams =
  | { id: string }
  | { configuration: { azureBlobConfiguration: ConfigAzureBlob } }
export const ACTION_AZURE_BLOB_SUBSCRIPTIONS = 'dataSources/azureBlobSubscriptions'
export const fetchAzureSubscriptions = createAsyncThunk(
  ACTION_AZURE_BLOB_SUBSCRIPTIONS,
  async (params: AzureBlobSubscriptionParams) => {
    return await service.getAzureBlobSubscriptions(params)
  }
)

export type AzureBlobStorageAccount = {
  id: string
  name: string
}
export type AzureBlobStorageAccountParams =
  | { id: string; configuration: { azureBlobConfiguration: { subscription_id: string } } }
  | {
      configuration: {
        azureBlobConfiguration: {
          clientId: string
          clientSecret: string
          tenantId: string
          subscription_id: string
        }
      }
    }
export const ACTION_AZURE_BLOB_STORAGE_ACCOUNTS = 'dataSources/azureBlobStorageAccounts'
export const fetchAzureBlobStorageAccounts = createAsyncThunk(
  ACTION_AZURE_BLOB_STORAGE_ACCOUNTS,
  async (params: AzureBlobStorageAccountParams) => {
    return await service.getAzureBlobStorageAccounts(params)
  }
)

export type AzureBlobContainer = {
  name: string
  region: string
  type?: string
  size?: string
  error?: string
}
export type AzureBlobContainerParams =
  | {
      id: string
      configuration: {
        azureBlobConfiguration: {
          subscription_id: string
          storageAccountId: string
          storageAccountName: string
        }
      }
    }
  | {
      configuration: {
        azureBlobConfiguration: {
          clientId: string
          clientSecret: string
          tenantId: string
          subscription_id: string
          storageAccountId: string
          storageAccountName: string
        }
      }
    }
export const ACTION_AZURE_BLOB_CONTAINER = 'dataSources/azureBlobContainers'
export const fetchAzureBlobContainers = createAsyncThunk(
  ACTION_AZURE_BLOB_CONTAINER,
  async (params: AzureBlobContainerParams) => {
    return await service.getAzureBlobContainers(params)
  }
)

export type ScanSettingsSearchParams =
  | { id: string; searchQuery?: string; type?: 'personal' | 'shared' }
  | {
      configuration: {
        datasourceType: string
        microsoftOfficeConfig?: ConfigMsOffice
        gsuite_configuration?: ConfigGSuite
        boxConfiguration?: ConfigBox
      }
      searchQuery?: string
      type?: 'personal' | 'shared'
    }
export const ACTION_SEARCH_DATASOURCE_MEMBERS = 'datasource/members/search'
export const fetchDatasourceMembers = createAsyncThunk(
  ACTION_SEARCH_DATASOURCE_MEMBERS,
  async (params: ScanSettingsSearchParams) => service.getMailMembers(params)
)

export const ACTION_SEARCH_MAIL_GROUPS = 'mail/groups/search'
export const fetchMailGroups = createAsyncThunk(
  ACTION_SEARCH_MAIL_GROUPS,
  async (params: ScanSettingsSearchParams) => service.getMailGroups(params)
)

// org domains
export type OrgDomain = {
  name: string
}
export type OrgDomainsParams =
  | { id: string }
  | {
      configuration: {
        datasourceType: string
        microsoftOfficeConfig?: ConfigMsOffice
      }
    }
export const ACTION_ORG_DOMAINS = 'datasource/orgDomains'
export const fetchOrgDomains = createAsyncThunk(
  ACTION_ORG_DOMAINS,
  async (params: OrgDomainsParams) => {
    const result = await service.getOrgDomains(params)
    return result?.domains
  }
)

// drives
export const ACTION_SEARCH_DRIVES = 'mail/drives/search'
export const searchDrives = createAsyncThunk(
  ACTION_SEARCH_DRIVES,
  async (params: ScanSettingsSearchParams) => {
    const drives = await service.getDrives(params)
    return drives.map((drive) => ({ ...drive, type: drive.driveType }))
  }
)

export const ACTION_FETCH_DS_DRIVES = 'mail/drives/fetch'
export const fetchDrives = createAsyncThunk(
  ACTION_FETCH_DS_DRIVES,
  async (params: ScanSettingsSearchParams) => {
    const drivesPrivate = await service.getDrives({ ...params, type: 'personal' })
    const drivesPublic = await service.getDrives({ ...params, type: 'shared' })
    return [...drivesPublic, ...drivesPrivate]
  }
)

// sites
export const ACTION_SEARCH_SHAREPOINT_SITES = 'sharepoint/sites/search'
export const searchSharePointSites = createAsyncThunk(
  ACTION_SEARCH_SHAREPOINT_SITES,
  async (params: ScanSettingsSearchParams) => {
    return await service.getSharePointSites(params)
  }
)

export interface DataSourcesListFilters {
  dataSourceTypes: DATA_SOURCE_TYPES[]
  statuses: DATASOURCE_STATUS[]
  createdBy: string[]
}

export interface MailMemberGroup {
  email: string
}
export interface SharePointSite {
  id: string
  name: string
}

export interface DatabasesSummary {
  count?: number
  name: string
}
interface DataSourcesState {
  entityWidget?: InstancesByDatasourceWidgetItem[]
  riskWidget?: DataSourceRiskyWidget
  listByEntity: DataSourceByEntitySettings
  unstructuredSummaryWidget?: UnstructuredSummaryWidget
  structuredSummaryWidget?: {
    [key: string]: StructuredSummaryWidget
  }
  all: DataSourceSettings
  risky: DataSourceSettings
  listSummary?: DataSourceSummary[]
  currentPage: number
  testConnection: DATASOURCE_CONNECTION | null
  testConnectionError?: string
  registerOrUpdateStatus: DATASOURCE_CREATE_OR_UPDATE_STATUS | null
  dataSourceToDelete: DataSourceToDelete | null
  selectedDataSource: DataSource | null
  isDataSourceCreated: boolean
  dataSourceId: string
  filters: DataSourcesListFilters
  owners?: string[]
  databasesSummary?: { [key: string]: DatabasesSummary[] }
  slackChannels?: {
    list: SlackChannel[]
    total: number
  }
  teamsChannels?: {
    list: MSTeamsChannel[]
    total: number
  }
  teams?: {
    list: MSTeamsTeam[]
    total: number
  }
  jiraProjects?: {
    list: JiraProject[]
    total: number
  }
  awsS3Buckets?: {
    list: AwsS3Bucket[]
    total: number
    listNotifications?: AwsS3Bucket[]
  }
  dropbox?: {
    oauthUrl?: string
  }
  azureBlobSubscriptions?: AzureBlobSubscription[]
  azureBlobStorageAccounts?: AzureBlobStorageAccount[]
  azureBlobContainers?: AzureBlobContainer[]
  members?: MailMemberGroup[]
  groups?: MailMemberGroup[]
  drives?: Drive[]
  sites?: SharePointSite[]
  orgDomains?: OrgDomain[]
}

export const initialState: DataSourcesState = {
  all: { ...initialList },
  risky: { ...initialList },
  listByEntity: { ...initialListByEntity },
  currentPage: 1,
  testConnection: null,
  dataSourceToDelete: null,
  selectedDataSource: null,
  isDataSourceCreated: false,
  registerOrUpdateStatus: null,
  dataSourceId: '',
  filters: {
    dataSourceTypes: [],
    statuses: [],
    createdBy: []
  }
}

const dataSourcesSlice = createSlice({
  name: 'dataSources',
  initialState,
  reducers: {
    resetEntityWidget: (state) => {
      state.entityWidget = initialState.entityWidget
    },
    resetRiskyWidget: (state) => {
      state.riskWidget = initialState.riskWidget
    },
    resetStructuredSummaryWidget: (state) => {
      state.structuredSummaryWidget = initialState.structuredSummaryWidget
    },
    resetUnstructuredSummaryWidget: (state) => {
      state.unstructuredSummaryWidget = initialState.unstructuredSummaryWidget
    },
    resetDataSourcesSensitiveTables: (state) => {
      state.databasesSummary = initialState.databasesSummary
    },
    resetDatasourceConnectionTest: (state) => {
      state.testConnection = null
    },
    resetSelectedDatasource: (state) => {
      state.selectedDataSource = initialState.selectedDataSource
      state.isDataSourceCreated = initialState.isDataSourceCreated
    },
    resetDatasourceOwners: (state) => {
      state.owners = initialState.owners
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setConnectionTest: (state, action) => {
      state.testConnection = action.payload
    },
    setRegisterOrUpdate: (state, action) => {
      state.registerOrUpdateStatus = action.payload
    },
    setDataSourceId: (state, action) => {
      state.dataSourceId = action.payload
    },
    setSort: (state, { payload }) => {
      state[payload.list].sort = getSortDirection(state[payload.list].sort, payload.column)
    },
    setDataSourceToDelete: (state, action) => {
      state.dataSourceToDelete = action.payload
    },
    resetDataSourceToDelete: (state) => {
      state.dataSourceToDelete = initialState.dataSourceToDelete
    },
    resetMembersAndGroups: (state) => {
      state.members = initialState.members
      state.groups = initialState.groups
    },
    resetDatasourceMembers: (state) => {
      state.members = initialState.members
    },
    resetMailGroups: (state) => {
      state.groups = initialState.groups
    },
    resetJiraProjects: (state) => {
      state.jiraProjects = initialState.jiraProjects
    },
    resetDataSourcesSummary: (state) => {
      state.listSummary = initialState.listSummary
    },
    resetBuckets: (state) => {
      state.awsS3Buckets = initialState.awsS3Buckets
    },
    resetDrives: (state) => {
      state.drives = initialState.drives
    },
    resetAzureBlobSubscriptions: (state) => {
      state.azureBlobSubscriptions = initialState.azureBlobSubscriptions
    },
    resetAzureBlobStorageAccounts: (state) => {
      state.azureBlobStorageAccounts = initialState.azureBlobStorageAccounts
    },
    resetAzureBlobContainers: (state) => {
      state.azureBlobContainers = initialState.azureBlobContainers
    },
    resetTestConnectionMessage: (state) => {
      state.testConnectionError = initialState.testConnectionError
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataSources.rejected, (state) => {
      state.all = initialList
      state.risky = initialList
    })
    builder.addCase(fetchDataSources.fulfilled, (state, { payload }) => {
      const { list, total } = payload

      const riskyDs = list.filter((ds) => ds.isRisky)
      state.risky.list = riskyDs
      state.risky.total = riskyDs.length
      state.all.list = list
      state.all.total = total
    })
    builder.addCase(fetchDataSourceFilters.fulfilled, (state, { payload }) => {
      state.filters = payload
    })

    builder.addCase(getSelectedDataSource.fulfilled, (state, action) => {
      const { data: dataSource } = action.payload
      state.selectedDataSource = dataSource
    })
    builder.addCase(getSelectedDataSource.rejected, (state) => {
      state.selectedDataSource = null
    })
    // TODO add right types for payload later
    builder.addCase(deleteDataSourceById.fulfilled, (state, { payload }: { payload: any }) => {
      state.dataSourceToDelete = initialState.dataSourceToDelete
      state.all.list = payload.list
      state.all.total = payload.total
    })
    builder.addCase(deleteDataSourceById.rejected, (state) => {
      state.dataSourceToDelete = initialState.dataSourceToDelete
    })
    builder.addCase(testDataSourceConnection.fulfilled, (state) => {
      state.testConnection = DATASOURCE_CONNECTION.success
    })
    builder.addCase(testDataSourceConnection.rejected, (state, { payload }) => {
      state.testConnection = DATASOURCE_CONNECTION.failed
      state.testConnectionError = payload as string
    })
    builder.addCase(
      fetchDropboxOauthRedirectUrl.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.dropbox = { ...(state.dropbox || {}) }
        state.dropbox.oauthUrl = payload.redirect_url
      }
    )
    builder.addCase(fetchDataSourcesRiskyWidget.fulfilled, (state, { payload }) => {
      state.riskWidget = payload
    })
    builder.addCase(fetchAttributeInstancesByDataSource.fulfilled, (state, { payload }) => {
      state.entityWidget = payload
    })
    builder.addCase(fetchDataSourcesByEntity.fulfilled, (state, { payload }) => {
      state.listByEntity.list = payload.list
      state.listByEntity.total = payload.total
    })
    builder.addCase(createDatasource.fulfilled, (state, { payload }: { payload: any }) => {
      state.isDataSourceCreated = true
      state.all.list = payload.list
      state.all.total = payload.total
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.created
      state.dataSourceId = payload.data
    })
    builder.addCase(createDatasource.rejected, (state) => {
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.createFailed
    })
    builder.addCase(createExchangeDatasource.fulfilled, (state, { payload }: { payload: any }) => {
      state.isDataSourceCreated = true
      state.all.list = payload.list
      state.all.total = payload.total
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.created
      state.dataSourceId = payload.data
    })
    builder.addCase(createExchangeDatasource.rejected, (state) => {
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.createFailed
    })
    builder.addCase(updateDatasource.fulfilled, (state) => {
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.updated
    })
    builder.addCase(updateDatasource.rejected, (state) => {
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.updateFailed
    })
    builder.addCase(updateDatasourceFields.fulfilled, (state) => {
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.updated
    })
    builder.addCase(updateDatasourceFields.rejected, (state) => {
      state.registerOrUpdateStatus = DATASOURCE_CREATE_OR_UPDATE_STATUS.updateFailed
    })
    builder.addCase(fetchDataSourcesUnstructuredSummary.fulfilled, (state, { payload }) => {
      state.unstructuredSummaryWidget = payload
    })
    builder.addCase(fetchDataSourcesStructuredSummary.fulfilled, (state, { payload }) => {
      state.structuredSummaryWidget = {
        ...state.structuredSummaryWidget,
        ...(payload.dataSourceId
          ? { [payload.dataSourceId]: payload.data }
          : { ['ALL']: payload.data })
      }
    })
    builder.addCase(fetchDataSourcesSensitiveTables.fulfilled, (state, { payload }) => {
      state.databasesSummary = {
        ...state.databasesSummary,
        [payload.dataSourceId]: payload.data
      }
    })
    builder.addCase(fetchDataSourceOwners.fulfilled, (state, { payload }) => {
      state.owners = payload
    })
    builder.addCase(fetchSlackChannels.fulfilled, (state, { payload }) => {
      state.slackChannels = payload
    })
    builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
      state.teams = payload
    })
    builder.addCase(fetchTeamsChannels.fulfilled, (state, { payload }) => {
      const team = state.teams?.list?.find((team) => team.id === payload?.list?.[0]?.teamId)
      payload.list = payload?.list?.map((channel) => ({ ...channel, team: team?.name }))
      state.teamsChannels = payload
    })
    builder.addCase(fetchJiraProjects.fulfilled, (state, { payload }) => {
      state.jiraProjects = payload
    })
    builder.addCase(fetchAwsS3Buckets.fulfilled, (state, { payload }) => {
      state.awsS3Buckets = payload
    })
    builder.addCase(fetchAwsBucketsNotifications.fulfilled, (state, { payload }) => {
      if (state.awsS3Buckets) {
        state.awsS3Buckets.listNotifications = payload.list
        state.awsS3Buckets.list = state.awsS3Buckets.list.map((bucket) => {
          const foundBucket = payload.list.find((b) => b.name === bucket.name)
          return foundBucket || bucket
        })
      }
    })
    builder.addCase(fetchAzureSubscriptions.fulfilled, (state, { payload }) => {
      state.azureBlobSubscriptions = payload
    })
    builder.addCase(fetchAzureBlobStorageAccounts.fulfilled, (state, { payload }) => {
      state.azureBlobStorageAccounts = payload
    })
    builder.addCase(fetchAzureBlobContainers.fulfilled, (state, { payload }) => {
      state.azureBlobContainers = payload
    })
    builder.addCase(fetchDatasourceMembers.fulfilled, (state, { payload }) => {
      state.members = payload
    })
    builder.addCase(fetchMailGroups.fulfilled, (state, { payload }) => {
      state.groups = payload
    })
    builder.addCase(searchDrives.fulfilled, (state, { payload }) => {
      state.drives = payload
    })
    builder.addCase(fetchDrives.fulfilled, (state, { payload }) => {
      state.drives = payload
    })
    builder.addCase(searchSharePointSites.fulfilled, (state, { payload }) => {
      state.sites = payload
    })
    builder.addCase(fetchDataSourcesSummary.fulfilled, (state, { payload }) => {
      state.listSummary = payload
    })
    builder.addCase(fetchOrgDomains.fulfilled, (state, { payload }) => {
      state.orgDomains = payload
    })
  }
})

export const {
  resetSelectedDatasource,
  setCurrentPage,
  setConnectionTest,
  resetDatasourceConnectionTest,
  setSort,
  setDataSourceToDelete,
  resetDataSourceToDelete,
  resetEntityWidget,
  resetRiskyWidget,
  resetStructuredSummaryWidget,
  resetUnstructuredSummaryWidget,
  resetDataSourcesSensitiveTables,
  resetDatasourceOwners,
  setRegisterOrUpdate,
  resetMembersAndGroups,
  setDataSourceId,
  resetDatasourceMembers,
  resetMailGroups,
  resetJiraProjects,
  resetDataSourcesSummary,
  resetBuckets,
  resetDrives,
  resetAzureBlobSubscriptions,
  resetAzureBlobStorageAccounts,
  resetAzureBlobContainers,
  resetTestConnectionMessage
} = dataSourcesSlice.actions

export default dataSourcesSlice.reducer
