import {
  DeleteEntityParam,
  EntitiesSummaryWidget,
  EntitiesSummaryWidgetParams,
  EntitiesWidgetCompact,
  EntitiesWidgetCompactParams,
  Entity,
  EntityTypeSummary,
  FetchSampleDataParams,
  LocalEntitiesParams,
  PutExtendLegalHoldEntityParam,
  SampleDataResponseType,
  SampleDataTable
} from './entitiesSlice'
import {
  API_FILTER_DATA_SOURCES,
  DATA_SOURCE_ID,
  GRAPHQL_API_FILTERS,
  LIMIT_DEFAULT
} from '../../constants'
import { IGetEntitiesParams } from '../../interfaces'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { Identifier } from '../../services/api/apiTypes'
import { ITableRow } from '../../components/SimpleGridTable'
import { gql } from 'graphql-request'

export const queryEntitiesSummary = (params: EntitiesSummaryWidgetParams): string => {
  const globalQuery = gql`
    {
      userEntities(first: 1) {
        count
      }
      userEntitiesRisky: userEntities(first: 1, booleanFilter: [{ key: IS_RISKY, value: true }]) {
        count
      }
      policyType {
        edges {
          node {
            id
            name
            description
            owner
            systemDefined
            riskyEntitiesCount
          }
        }
      }
    }
  `

  const dataSourceFilterQuery = gql`
  {
    datasources(id: "${params[DATA_SOURCE_ID]}"){
      edges{
        node{
          userEntities(first: 1) {
            count
          }
          userEntitiesRisky: userEntities(first: 1,  booleanFilter:[{key: IS_RISKY, value: true}]) {
            count
          }
        }
      }
    }
    policyType {
      edges {
        node {
          id
          name
          description
          owner
          systemDefined
          riskyEntitiesCount(datasourceId: "${params[DATA_SOURCE_ID]}")
        }
      }
    }
  }`

  const query = params[DATA_SOURCE_ID] ? dataSourceFilterQuery : globalQuery

  return gql`
    ${query}
  `
}

// TODO: add type for graphql response
export const mapQueryEntitiesSummary = (raw: any): EntitiesSummaryWidget => {
  const root = raw.datasources ? raw.datasources.edges[0].node : raw
  const list = raw.policyType.edges.map(({ node: policyData }) => ({
    id: policyData.id,
    name: policyData.name,
    description: policyData.description,
    owner: policyData.owner,
    systemDefined: policyData.systemDefined,
    entitiesInRiskCount: policyData?.riskyEntitiesCount || 0
  }))

  return {
    total: root.userEntities?.count || 0,
    totalRisky: root.userEntitiesRisky?.count || 0,
    list
  }
}

export const queryEntitiesWidgetCompactDataSourceInfo = (
  params: EntitiesWidgetCompactParams
): string => {
  return gql`
    {
      databases(first: 1, id: "${params.databaseId}") {
        edges {
          node {
            id
            name
            datasource {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryEntitiesWidgetCompactDataSourceInfo = (
  raw: any
): {
  dataSourceId: string
  dataSourceName: string
  databaseId: string
  databaseName: string
} => {
  try {
    return {
      dataSourceId: raw.databases.edges[0]?.node?.datasource?.edges[0]?.node?.id || '',
      dataSourceName: raw.databases.edges[0]?.node?.datasource?.edges[0]?.node?.name || '',
      databaseId: raw.databases.edges[0]?.node?.id || '',
      databaseName: raw.databases.edges[0]?.node?.name || ''
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryEntitiesWidgetCompact = (params: {
  datasourceId: string
  databaseName: string
}): string => {
  return gql`
    {
      datasources(id: "${params.datasourceId}") {
        edges {
          node {
            userEntities(database: "${params.databaseName}") {
              count
            }
          }
        }
      }
    }
  `
}

// TODO: add type for graphql response
export const mapQueryEntitiesWidgetCompact = (raw: any): EntitiesWidgetCompact => {
  try {
    return {
      entitiesCount: raw.datasources.edges[0]?.node?.userEntities?.count || 0
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

function parseGetEntitiesParams({
  page = 1,
  filters,
  datasourceId,
  attributeFilters
}: IGetEntitiesParams) {
  const cursor = page ? getAfterCursor(page, LIMIT_DEFAULT) : ''
  let commonParamString = `first:${LIMIT_DEFAULT}, after: "${cursor}"`

  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''
  if (filterString) {
    commonParamString += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    commonParamString += `, booleanFilter: ${booleanFilterString}`
  }

  const dateFilterString = filters?.dateFilter?.end
    ? `{
        key: ${API_FILTER_DATA_SOURCES.lastModifiedTime},
        start: ${filters?.dateFilter.start},
        end:${filters?.dateFilter.end}
      }`
    : ''
  if (dateFilterString) {
    commonParamString += `, dateFilter: ${dateFilterString}`
  }
  if (attributeFilters) {
    const attributeFiltersObject = JSON.parse(attributeFilters)
    const keys = Object.getOwnPropertyNames(attributeFiltersObject)

    if (keys.length > 0) {
      commonParamString += `, attributesFilter: {filters: [
        ${keys
          .map((attributeId) => {
            return `{attributeId: "${attributeId}", attributeValues: ${JSON.stringify(
              attributeFiltersObject[attributeId]
            )}}`
          })
          .join(', ')}
      ]}`
    }
  }

  const datasourceString = datasourceId?.trim() ? `datasourceId: "${datasourceId?.trim()}"` : ''

  const queryFilters = commonParamString
    ? `(${commonParamString}, ${datasourceString})`
    : `(${datasourceString})`

  return queryFilters
}

export const queryEntityList = (params: IGetEntitiesParams): string => {
  const { isJira } = params
  const queryFilters = parseGetEntitiesParams(params)

  const optionalFields = isJira ? 'ticketsCount' : 'objects { count }'

  return gql`
    {
      userEntities${queryFilters} {
        edges {
          node {
            id
            name
            riskStatus
            lastModifiedTime
            attributesCount
            tableClusterName
            datasources {
              count
            }
            type{
              edges{
                node{
                  name
                }
              }
            }
            ${
              window.__featureFlags.accessControl
                ? `userAccess {
              count
            }
            groupAccess {
              count
            }`
                : ''
            }

            ${optionalFields}
          }
        }
      }
    }
  `
}
export const mapQueryEntityList = (raw: any): { list: Entity[]; total: number } => {
  try {
    const list: Entity[] =
      raw.userEntities.edges?.map(({ node: entity }) => ({
        id: entity.id,
        name: entity.name && entity.name[0] ? entity.name[0] : '',
        objectsCount: entity.objects?.count || 0,
        riskStatus: entity.riskStatus,
        datasourceCount: entity.datasources?.count || 0,
        attributesCount: entity.attributesCount,
        ticketsCount: entity.ticketsCount || 0,
        entityType: entity.type?.edges[0]?.node?.name || '',
        isOnLegalHold: entity.legalHoldStatus?.isOnLegalHold,
        hasHoldExpired: entity.legalHoldStatus?.hasHoldExpired,
        lastModifiedTime: entity.lastModifiedTime,
        tableCluster: entity.tableClusterName,
        deletedUserEntityReferences: '', // not presented on graphql API
        userAccessCount: entity.userAccess?.count || 0,
        groupAccessCount: entity.groupAccess?.count || 0
      })) || []
    return { list, total: raw.userEntities?.count || 0 }
  } catch (e) {
    console.error(e)
    return { list: [], total: 0 }
  }
}

export const queryEntityCount = (params: IGetEntitiesParams): string => {
  const queryFilters = parseGetEntitiesParams(params)

  return gql`
  {
    userEntities${queryFilters} {
      count
    }
  }`
}

export const queryLocalEntities = ({
  userEntityId,
  [DATA_SOURCE_ID]: datasourceId
}: LocalEntitiesParams) => {
  let datasourceIDStr = ''
  if (datasourceId) {
    datasourceIDStr = `,${DATA_SOURCE_ID}: "${datasourceId}"`
  }

  return gql`
  {
  userEntities(id:"${userEntityId}"${datasourceIDStr}) {
    edges {
      node {
        id
        attributesCount
        localEntities(first: ${LIMIT_DEFAULT}) {
          count
          edges {
            node {
              id
              attributes {
                attributeType
                attributeValues
              }
            }
          }
        }
      }
    }
  }
}`
}
export const mapQueryLocalEntities = (raw: any) => {
  try {
    // Data queried for single entity only
    const parentEdge = raw.userEntities.edges[0]?.node
    return {
      attributesCount: parentEdge.attributesCount || 0,
      localEntitiesCount: parentEdge.localEntities?.count || 0,
      localEntities: parentEdge.localEntities?.edges?.map(({ node: localEntity }) => ({
        id: localEntity.id,
        attributes: localEntity.attributes.reduce((acc, attribute) => {
          acc[attribute.attributeType] = attribute.attributeValues.join()
          return acc
        }, {})
      }))
    }
  } catch (e) {
    console.error(e)
    return { attributesCount: 0, localEntitiesCount: 0 }
  }
}

export const queryEntityTypesSummary = () => gql`
  {
    entityType {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const mapQueryEntityTypesSummary = (raw: any): EntityTypeSummary[] => {
  try {
    const list =
      raw.entityType.edges?.map(({ node: entityType }) => ({
        id: entityType.id,
        name: entityType.name
      })) || []
    return list
  } catch (e) {
    console.error(e)
    return []
  }
}

export const queryIdentifiers = (): string => {
  return gql`
    {
      attribute(booleanFilter: [
        { key: ${GRAPHQL_API_FILTERS.isCategory}, value: false },
      ]) {
        edges {
          node {
            id
            name
            internalName
          }
        }
      }
    }
  `
}
export const mapQueryIdentifiers = (raw: any): Identifier[] => {
  try {
    const list = raw.attribute.edges.map(({ node: identifier }) => ({
      id: identifier.id,
      name: identifier.name,
      internalName: identifier.internalName
    }))

    return list
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryDeleteEntity = ({ entityId }: DeleteEntityParam) => {
  return gql`
    mutation {
      deleteEntity(id: "${entityId}") {
        success
      }
    }
  `
}

export const queryPutEntityLegalHold = ({ entityId, duration }: PutExtendLegalHoldEntityParam) =>
  gql`
    mutation {
      putEntityOnLegalHold(
        input: { entityId: "${entityId}", legalHoldStatus: { duration: ${duration}, enableLegalHold: true}}
      ) {
        success
        message
      }
    }
  `
export const queryExtendEntityLegalHold = ({ entityId, duration }: PutExtendLegalHoldEntityParam) =>
  gql`
      mutation {
        extendEntityLegalHold(
          input: { entityId: "${entityId}", legalHoldStatus: { duration: ${duration}, enableLegalHold: true}}
        ) {
          success
        }
      }
    `

export const queryRemoveEntityLegalHold = ({ entityId }: DeleteEntityParam) =>
  gql`
    mutation {
      putEntityOnLegalHold(
        input: { entityId: "${entityId}",  legalHoldStatus: {enableLegalHold: false} }
      ) {
        success
      }
    }
  `

export const querySampleData = ({ entityId }: FetchSampleDataParams): string =>
  gql`
  {
    userEntities(id: "${entityId}") {
      edges {
        node {
          id
          name
          entityRecords {
            header
            rows
            tableName
            count
          }
        }
      }
    }
  }

  `

export const mapQuerySampleData = (raw: any): SampleDataResponseType => {
  try {
    const sampleDataList: SampleDataTable[] = raw.userEntities.edges[0].node.entityRecords.map(
      ({ header, rows, tableName }) => ({
        tableName,
        header,
        rows: rows.map((row: any) => {
          const rowData: ITableRow = {}
          header.forEach((key: string, index: number) => {
            rowData[key] = row[index]
          })
          return rowData
        })
      })
    )
    const entityName: string = raw.userEntities.edges[0].node.name
    const totalRecords: number = raw.userEntities.edges[0].node.entityRecords.reduce(
      (sum, record) => sum + record.count,
      0
    )
    return { sampleDataList, entityName: entityName || '', totalRecords }
  } catch (error) {
    return { sampleDataList: [], entityName: '', totalRecords: 0 }
  }
}
